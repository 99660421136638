svg g path,
svg g image {
  cursor: pointer;
}
svg.fill-me g path:hover,
svg.fill-me g image:hover {
  fill: #f89ea1;
}
svg .active {
  fill: #ff8185;
}
svg g.letter path,
svg g.letter path:hover {
  fill: #e6e6e6;
  cursor: default;
}

.disable-bias {
  opacity: 0.4;
}
